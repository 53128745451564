<template>
  <div class="content">
    <el-form ref="form" :rules="rules" v-show="!isLook" :model="form" label-width="100px">
      <el-form-item label="应用类型" prop="applicationType">
        <el-select
          v-model.trim="form.applicationType"
          @change="enumsSelect(form.applicationType)"
          :disabled="isEdit"
          filterable
          size="15"
        >
          <el-option
            :label="value.desc"
            :value="value.code"
            :key="value.code"
            v-for="value in enumsList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应用名称" prop="applicationName">
        <el-input v-model="form.applicationName" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="APPID" prop="applicationId">
        <el-input :disabled="isEdit" v-model="form.applicationId" :maxlength="50"></el-input>
      </el-form-item>
      <el-form-item
        label="APPSecret"
        prop="appSecret"
        v-if="
          form.applicationType == 11 || form.applicationType == 12 || form.applicationType == 13
        "
      >
        <el-input :disabled="isEdit" :maxlength="50" v-model="form.appSecret"></el-input>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Payment_Scenario')" prop="payScenes">
        <el-checkbox-group v-model="form.payScenes" @change="handlePayScenesChange">
          <el-checkbox v-for="value in payList" :label="value.code" :key="value.code">{{
            value.desc
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ $t('button.preservation') }}</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </el-form-item>
    </el-form>
    <el-form v-show="isLook" :model="form" label-width="100px">
      <el-form-item label="应用类型">
        {{ form.applicationTypeName }}
      </el-form-item>
      <!--<el-form-item label="应用类型"-->
      <!--prop="applicationType">-->
      <!--<el-select v-model.trim="form.applicationType"-->
      <!--@change="enumsSelect(form.applicationType)"-->
      <!--filterable-->
      <!--size="15">-->
      <!--<el-option :label="value.desc"-->
      <!--:value="value.code"-->
      <!--:key="value.code"-->
      <!--v-for="value in enumsList"></el-option>-->
      <!--</el-select>-->
      <!--</el-form-item>-->
      <el-form-item label="应用名称">
        {{ form.applicationName }}
      </el-form-item>
      <el-form-item label="APPID">
        {{ form.applicationId }}
      </el-form-item>
      <el-form-item
        label="APPSecret"
        v-if="
          form.applicationType == 11 || form.applicationType == 12 || form.applicationType == 13
        "
      >
        {{ form.appSecret }}
      </el-form-item>
      <el-form-item :label="$t('searchModule.Payment_Scenario')">
        <el-checkbox-group
          v-model="form.payScenes"
          @change="handlePayScenesChange"
          :disabled="true"
        >
          <el-checkbox v-for="value in payList" :label="value.code" :key="value.code">{{
            value.desc
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "appConfigDetail",
  data() {
    return {
      rules: {
        applicationType: [
          {
            required: true,
            message: "请选择应用类型",
            trigger: "blur",
          },
        ],
        applicationName: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        applicationId: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        appSecret: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        payScenes: [
          {
            required: true,
            message: "请选择支付场景",
            trigger: "blur",
          },
        ],
      },
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        payScenes: [],
        applicationType: "",
      },
      enumsList: [],
      payList: [],
      isLook: false,
      isEdit: false,
    };
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = this.isEdit ? "/acb/2.0/application/update" : "/acb/2.0/application/add/";
          this.$axios
            .post(url, {
              data: {
                applicationId: this.form.applicationId,
                applicationName: this.form.applicationName,
                applicationType: this.form.applicationType,
                payScenes: this.form.payScenes.join(","),
                appSecret: this.form.appSecret,
              },
            })
            .then((res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                this.form = res.value;
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                }).then(() => {
                  this.$router.go(-1);
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    // 应用类型下拉框
    getenumsList() {
      this.$axios.get("/acb/2.0/application/enums").then((res) => {
        if (res.state == 0) {
          this.enumsList = res.value.addApplicationType;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 编辑和查看
    getDetail() {
      this.$axios
        .get("/acb/2.0/application/detail/", {
          data: {
            applicationId: this.$route.query.applicationId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.form = res.value;
            this.form.appSecret = res.value.attributes.appSecret;
            this.form.payScenes = res.value.payScenes.split(",").map(Number);
            this.enumsSelect();
          }
        });
    },
    enumsSelect(a) {
      for (var i = 0; i < this.enumsList.length; i++) {
        if (this.form.applicationType == this.enumsList[i].code) {
          this.payList = this.enumsList[i].paySceneList;
          // this.form.payScenes = []
        }
      }
      if (this.$route.query.state === "add") {
        this.form.payScenes = [];
      }
    },
    handlePayScenesChange(val) {
      // console.log(val, 'kkk')
      // console.log(this.form.payScenes, 'payScenes')
    },
  },
  created() {
    this.getenumsList();
  },
  mounted() {
    if (this.$route.query.state == "edit") {
      this.isEdit = true;
    }
    if (this.$route.query.state != "add") {
      this.getDetail();
    }
    // if (this.$route.query.state != 'detail') {
    //   this.getenumsList()
    // }
    if (this.$route.query.state == "detail") {
      this.isLook = true;
    }
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
  .el-input
    width 20%
</style>
